import * as React from "react";
import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Checkbox } from "rsuite"

import { AlbumTitles, defaultCheckedAlbums, entireCatalog } from "../Catalog/catalog";
import { AlbumID, ReleaseType } from "../Catalog/types";
import { AlbumSettingContainer } from "./AlbumSettingContainer";
import { UserSettingsContainer } from "./UserSettingsContainer";
import { AlbumDirectionContainer } from "./AlbumDirectionContainer"
import { getAlbumOrder } from "./utils"
import { gaEvents } from "../events"
import { StyledAlbumSetting } from "./StyledAlbumSetting";
import { allAlbums, erasTourSetlist } from "../Catalog/catalog";
import { fonts } from "../Style";
import { RevealVocalizationsContainer } from "./RevealVocalizationsContainer";
import { useCookies } from "react-cookie";
import { useIsMobile } from "../hooks/windowsize";

const StyledLine = styled.div`
  border: 1px solid #d8d8d8;
  margin: 10px 4px;
`

const StyledDottedLine = styled.div`
  border: 1px dotted #d8d8d8;
  margin: 10px 4px;
`

const StyledNormalSpan = styled.span`
    font-family: ${fonts.serif}
`

const StyledSettings = styled.div<{
    isMobile: boolean
}>`
    font-size: 1em;
    text-align: center;
    grid-area: sidebar;

  ${({ isMobile }) => {
        if (isMobile) {
            return `
                display: grid;
                grid-template-columns: 1fr 1fr;
            `
        } else {
            return `
                margin-left: 20px;
                margin-top: 60px;
                z-index: 1;
                top: 0;
                left: 0;
                background-color: white;
                overflow-x: hidden;
                overflow-y: scroll;
            `
        }
    }}
`

export function SettingsContainer ({
    setElligibleSongs,
    leftToRight,
    randomOrder,
    revealVocalizations,
    updateUserSetting,
}: {
    setElligibleSongs: React.Dispatch<React.SetStateAction<string[]>>
    leftToRight: boolean
    randomOrder: boolean
    revealVocalizations: boolean
    updateUserSetting: (setting: string, value: boolean) => void
}) {
    const allAlbumsToggleRef = useRef(null)
    const isMobile = useIsMobile()

    // TODO where should this live?
    const COOKIE_SUFFIX = '-cookie'

    // Why do I have all albums and also AlbumTitles.keys???
    const allCookies = Object.keys(AlbumTitles).map(k => k + COOKIE_SUFFIX)
    const [cookies, setCookies] = useCookies([...allCookies, 'erasTourSelected'])

    const anyCookiesSet = allCookies.some(aID => cookies[aID])

    const [albumCheckedState, setAlbumCheckedState] = useState(
        Object.fromEntries(
            Object.keys(AlbumTitles).map(albumID => {
                const cookieString = albumID + COOKIE_SUFFIX
                const cookieSet = cookies[cookieString] === undefined ? false : cookies[cookieString] as boolean

                const defaultAlbum = defaultCheckedAlbums.includes(albumID)
                const overideCookie = defaultAlbum && !anyCookiesSet

                return [albumID, cookieSet || overideCookie];
            }
            )
        )
    )
    const allAlbumsChecked = allAlbums.every((albumbID: AlbumID) => albumCheckedState[albumbID])
    const noAlbumsChecked = allAlbums.every((albumID: AlbumID) => !albumCheckedState[albumID])

    const erasTourCookieSet = cookies['erasTourSelected'] as boolean
    const [erasTourChecked, setErasTourChecked] = useState(erasTourCookieSet)
    const [albumsModeChecked, setAlbumsModeChecked] = useState(!erasTourCookieSet)

    useEffect(() => {
        if (noAlbumsChecked || allAlbumsChecked) {
            allAlbumsToggleRef.current.indeterminate = false
        } else {
            allAlbumsToggleRef.current.indeterminate = true
        }
    }, [allAlbumsChecked, noAlbumsChecked])

    const handleOnAlbumSettingChange = (aID: AlbumID) => {
        toggleAlbumsModeOn()

        let updatedAlbumState = albumCheckedState
        const newState = !albumCheckedState[aID]

        updatedAlbumState[aID] = newState
        setAlbumCheckedState(updatedAlbumState)

        setCookies(aID + COOKIE_SUFFIX, newState)

        updateElligibleSongs()
    }

    const toggleAlbumsModeOn = () => {
        setErasTourChecked(false)
        setCookies('erasTourCookieSet', false)
        setAlbumsModeChecked(true)
    }

    const toggleErasTourOn = () => {
        setCookies('erasTourCookieSet', true)
        setErasTourChecked(true)
        setAlbumsModeChecked(false)
    }


    const handleErasTourChecked = () => {
        toggleErasTourOn()
        gaEvents.logSelectErasTourClicked()
        setElligibleSongs(erasTourSetlist)
    }

    const handleAllAlbumChange = () => {
        toggleAlbumsModeOn()

        let newCheckedState = true
        if (allAlbumsChecked) {
            newCheckedState = false
        }

        let updatedAlbumState = albumCheckedState

        allAlbums.forEach((aID: AlbumID) => {
            setCookies(aID + COOKIE_SUFFIX, newCheckedState)
            updatedAlbumState[aID] = newCheckedState;
        })

        gaEvents.logSelectAllAlbumsClicked()
        setAlbumCheckedState(updatedAlbumState)
        updateElligibleSongs()
    }

    const updateElligibleSongs = () => {
        const selectedAlbums = Object
            .keys(albumCheckedState)
            .filter(album => albumCheckedState[album])
        gaEvents.logAlbumsUpdated(selectedAlbums)

        const elligibleSongs = Object
            .keys(entireCatalog)
            .filter(songID => {
                const songAlbum = entireCatalog[songID].album
                return albumCheckedState[songAlbum]
            }).filter(songID => entireCatalog[songID].releaseType !== ReleaseType.surpriseSongDoNotPlay)

        setElligibleSongs(elligibleSongs)
        console.log(elligibleSongs)
        console.log(albumCheckedState)
    }

    let displayOrder = getAlbumOrder(Object.keys(AlbumTitles), isMobile)

    return <StyledSettings isMobile={isMobile}>
        {
            !isMobile && <>
                <StyledAlbumSetting albumID='eras_tour' disabled={!erasTourChecked}>
                    <Checkbox
                        readOnly
                        name={'erasTour'}
                        value={'eras_tour'}
                        checked={erasTourChecked}
                        onChange={() => handleErasTourChecked()}
                    >
                        &nbsp;The Eras Tour <StyledNormalSpan>(Main Setlist)</StyledNormalSpan>
                    </Checkbox>
                </StyledAlbumSetting>
                <StyledDottedLine />
            </>
        }
        <>
            <StyledAlbumSetting albumID='' disabled={!albumsModeChecked}>
                <div onChange={() => handleAllAlbumChange()}>
                    <input
                        readOnly
                        type='checkbox'
                        name={'allAlbums'}
                        value={'allAlbums'}
                        checked={allAlbumsChecked}
                        ref={allAlbumsToggleRef}
                        id='allAlbums'
                    />
                    <label htmlFor="allAlbums">&nbsp;All albums</label>
                </div>
            </StyledAlbumSetting>
            {
                displayOrder.map(albumID =>
                    <AlbumSettingContainer
                        disabled={!albumsModeChecked}
                        key={albumID}
                        albumID={albumID}
                        title={AlbumTitles[albumID].title}
                        displayTitle={AlbumTitles[albumID].displayTitle}
                        albumCheckedState={albumCheckedState[albumID]}
                        handleOnChange={handleOnAlbumSettingChange}
                    />
                )
            }
        </>
        {
            !isMobile && <>
                <StyledLine />
                <RevealVocalizationsContainer revealVocalizations={revealVocalizations} updateUserSetting={updateUserSetting} />
                <StyledDottedLine />
                <AlbumDirectionContainer randomOrder={randomOrder} updateUserSetting={updateUserSetting} />
                <StyledDottedLine />
                <UserSettingsContainer leftToRight={leftToRight} updateUserSetting={updateUserSetting} />
            </>
        }

    </StyledSettings>
}