export type SongID = string

export enum ReleaseType {
  mainAlbum,
  bonusTrack,
  vaultTrack,
  platniumTrack,
  collaboration,
  remix,
  threeAMEdition,
  lateNightEdition,
  charitySingle,
  beautifulEyesEP,
  movieFeature,
  holiday_collection,
  unreleased,
  surpriseSongDoNotPlay,
  unknown,
}

export const releaseTypeToDisplayString: { [key in ReleaseType]: string } = {
  [ReleaseType.mainAlbum]: 'Main Album',
  [ReleaseType.holiday_collection]: 'Holiday Collection',
  [ReleaseType.bonusTrack]: 'Bonus Track',
  [ReleaseType.vaultTrack]: 'Vault Track',
  [ReleaseType.movieFeature]: 'Movie Feature',
  [ReleaseType.beautifulEyesEP]: 'Beautiful Eyes EP',
  [ReleaseType.platniumTrack]: 'Platinum Track',
  [ReleaseType.collaboration]: 'Collaboration',
  [ReleaseType.remix]: 'Remix',
  [ReleaseType.threeAMEdition]: 'Three AM Edition',
  [ReleaseType.lateNightEdition]: 'Late Night Edition',
  [ReleaseType.charitySingle]: 'Charity Single',
  [ReleaseType.unknown]: 'Unknown',
  [ReleaseType.surpriseSongDoNotPlay]: 'shouldnt show to users lol',
  [ReleaseType.unreleased]: 'Unreleased',
}

export const releaseTypeToPluralDisplayString: {
  [key in ReleaseType]: string
} = {
  [ReleaseType.mainAlbum]: 'Main Album Tracks',
  [ReleaseType.holiday_collection]: 'Holiday Collections',
  [ReleaseType.bonusTrack]: 'Bonus Tracks',
  [ReleaseType.movieFeature]: 'Movie Features',
  [ReleaseType.platniumTrack]: 'Platinum Tracks',
  [ReleaseType.vaultTrack]: 'Vault Tracks',
  [ReleaseType.beautifulEyesEP]: 'Beautiful Eyes EP Songs',
  [ReleaseType.collaboration]: 'Collaborations',
  [ReleaseType.remix]: 'Remixes',
  [ReleaseType.threeAMEdition]: 'Three AM Editions',
  [ReleaseType.lateNightEdition]: 'Late Night Editions',
  [ReleaseType.charitySingle]: 'Charity Singles',
  [ReleaseType.unknown]: 'Unknown',
  [ReleaseType.surpriseSongDoNotPlay]: 'shouldnt show to users',
  [ReleaseType.unreleased]: 'Unreleased Songs',
}

export type Song = {
  title: string
  lyrics: string
  album: AlbumID
  id: string
  releaseType: ReleaseType
  ogReleaseDate: string
  tvReleaseDate?: string
  touchDate?: string
  popular?: boolean
  releasedAsSingle?: boolean
  spotifyLink?: string
  collaborator?: string
  movieTitle?: string
  effectiveTitle?: string
}

export type Catalog = Record<SongID, Song>

export type AlbumID = string

export type Album = {
  title: string
  albumID: AlbumID
  trackList?: SongID[]
  displayTitle?: string
}

export type Albums = Record<AlbumID, Album>

export type AllAlbumType = Record<AlbumID, Catalog>
