import styled from 'styled-components';
import { PageDestination, PageDisplay, PageTypeCase, PageTypes } from './data';

const StyledFooter = styled.div<{
    mainPage: boolean
}>`
    ${({ mainPage }) => {
        if (mainPage) {
            return `
            justify-items: center;
            justify-content: center;

            justify-content: center;
            text-align: center;
        `
        } else {
            return `
                grid-area: footer;

                display: flexbox;
                justify-content: flex-end;
                text-align: right;
            `
        }
    }}

`

const StyledFooterEntry = styled.div<{
    darkMode: boolean
}>`
    color: #808080;
    font-style: italic;
    padding-right: 15px;

    > a {
        color: #2986cc;
    }

    // lol this could be worse.  I should figure out how to make this be an ACTUAL footer
    ${({ darkMode }) => {
        if (darkMode) {
            return `
                color: white;
                > a {
                    color: white;
                }
            `
        }
    }}
`

const StyledWebPageThing = styled(StyledFooterEntry)`
    color: #404040;
    font-size: 18px;
    margin-top: 40px;
    margin-bottom: 5px;
    font-weight: 550;
`

export function Footer ({
    currentPage = "songGuesser",
    mobileVersion = false,
    mainPage = false,
}: {
    currentPage?: PageTypeCase
    mobileVersion?: boolean
    mainPage?: boolean
}) {
    const footerLinks = PageTypes.filter(p => p !== currentPage)
    const darkMode = currentPage === "changeLog"

    return <StyledFooter mainPage={mainPage}>
        {
            mainPage && <StyledWebPageThing darkMode={darkMode}>
                TSSongGuesser.com
            </StyledWebPageThing>
        }
        <StyledFooterEntry darkMode={darkMode}>
            {
                !mobileVersion && <>
                    Made with 💗 by <a target="_blank" href="https://bsky.app/profile/tesshuelskamp.bsky.social" rel="noreferrer">Tess Huelskamp</a>
                    {currentPage !== 'surpriseSongs' &&
                        <>
                            &nbsp;<span style={{ "fontStyle": "normal" }}>|</span> &copy; 2023-2025
                        </>
                    }
                    <br />
                </>
            }
            {
                footerLinks.map((page, idx) => <span key={page}>
                    {idx !== 0 && <span style={{ "fontStyle": "normal" }}> | </span>}
                    <a href={PageDestination[page]}>{PageDisplay[page]}</a>
                </span>
                )
            }
            {
                currentPage === "songGuesser" && <div>
                    <br />
                    Questions, comments, lyric issues, bugs?

                    <br />pls feel free to email me :&#41;
                    <br />tess DOT huelskamp AT gmail DOT com</div>
            }
        </StyledFooterEntry>
    </StyledFooter >
}