import { sanitizeInput } from '../Util/utils'
import { entireCatalog } from './catalog'

// these aren't technicaly homophones but you get the point
export const homophones = [
  [
    'ah',
    'ahah',
    'ahahah',
    'ahahahah',
    'ahahahahah',
    'ahahahahahah',
    'haahha',
    'ahahha',
    'haah',
    'oh',
    'ohh',
    'ohhh',
    'ohhoh',
    'ohoh',
    'ohohah',
    'ohohoh',
    'ohohohoh',
    'ohwhoa',
    'ooh',
    'oohah',
    'oohooh',
    'oohoohooh',
    'oohoohoohooh',
    'ohohohohoh',
    'ohohohohohohohohoh',
    'oohwhoa',
    'oohwoooohooh',
    'uh',
    'uhoh',
    'uhhuh',
    'uhuh',
    'whoa',
    'whoaoh',
    'whoawhoa',
    'whoawhoaohoh',
    'woah',
    'woahoh',
  ],
  ['yeah', 'yea', 'ay', 'eh', 'yeahyeah', 'hey'],
  ['ha', 'haha', 'hahaha'],
  ['i', 'ii', 'iii', 'e', 'eeheeheeh', 'hee'],
  ['la', 'lala', 'lalala', 'lalalala', 'lalalalala'],
  [
    'mm',
    'mmm',
    'mmmm',
    'mmmmm',
    'mmmmmm',
    'mmmmmmm',
    'hm',
    'hmm',
    'hmmm',
    'hmmmm',
  ],

  ['1', 'one'],
  ['1st', 'first'],
  ['2', 'two'],
  ['3', 'three'],
  ['4', 'four'],
  ['4th', 'fourth'],
  ['5', 'five'],
  ['6', 'six'],
  ['6lane', 'sixlane'],
  ['7', 'seven'],
  ['8', 'eight'],
  ['9', 'nine'],
  ['9s', 'nines'],
  ['9th', 'ninth'],
  ['10', 'ten'],
  ['15', 'fifteen'],
  ['16', 'sixteen'],
  ['16th', 'sixteenth'],
  ['17', 'seventeen'],
  ['18', 'eighteen'],
  ['19', 'nineteen'],
  ['19s', 'nineteens'],
  ['20', 'twenty'],
  ['20year', 'twentyyear'],
  ['21', 'twentyone'],
  ['22', 'twentytwo'],
  ['23', 'twentythree'],
  ['24', 'twentyfour'],
  ['25', 'twentyfive'],
  ['230', 'twothirty'],
  ['29', 'twentynine'],
  ['30', 'thirty'],
  ['30s', 'thirties'],
  ['32', 'thirtytwo'],
  ['45', 'fourtyfive'],
  ['50', 'fifty'],
  ['90', 'ninety'],
  ['100s', 'hundreds'],
  ['100', 'hundred'],
  ['1000', 'thousand', 'athousand'],
  ['300', 'threehundred'],
  ['twenties', '20s'],
  ['nineteenfifties', '1950s'],

  ['sorceress', 'sorcerers'],
  ['around', 'round'],
  ['another', 'nother'],
  ['before', 'fore'],
  ['want', 'wanna'],
  ['dorothea', 'dorthea'],
  ['cheser', 'cheshire'],
  ['steven', 'stephen'],
  ['bout', 'about'],
  ['cause', 'cuz', 'cos', 'because'],
  ['ya', 'you'],
  ['ok', 'okay'],
  ['me', 'meee'],
  ['rebekah', 'rebecca', 'rebeca'],
  ['behaviour', 'behavior'],
  ['grah', 'grrah'],
  ['glad', 'glaad'],
  ['grey', 'gray'],
  ['dominoes', 'dominos'],
  ['tryin', 'tryna'],
  ['em', 'them'],
  ['receipts', 'reciepts'],
  ['triptrippin', 'trip', 'trippin'],
  ['skipskippin', 'skip', 'skippin'],
  ['refrigerator', 'frigerator', 'fridge'],
  ['island', 'iisland'],
  ['elegies', 'ellegies'],
  ['vendetta', 'vendettata', 'vendeta'],
  ['cancelled', 'canceled'],
  ['teeball', 'tball'],
  ['echos', 'echoes'],
  ['machieveallan', 'machiavellian', 'machievallian', 'machiavellan'],
  ['kaleidoscope', 'kaleidoscope', 'kladeiscope'],
  ['insaneane', 'insane'],
  ['mustve', 'musta'],
  ['until', 'til'],
  ['solitaire', 'solitare'],
  ['eyeyes', 'eyes', 'eeeyes'],
  ['ticktock', 'tiktock', 'tiktok'],
  ['samitarin', 'samaritan'],
  ['going', 'goin'],
  ['mama', 'momma'],
]

export const homophoneDict = Object.fromEntries(
  homophones.flatMap((list) => {
    const result = []
    for (const element of list) {
      result.push([element, list])
    }
    return result
  })
)

export const dictionary = new Set(
  Object.values(entireCatalog)
    .flatMap((s) => s.lyrics.split(' '))
    .map((w) => sanitizeInput(w))
)

export const vocalizations = ['ah', 'yeah', 'ha', 'ii', 'la', 'mm', 'hey']
